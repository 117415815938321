import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';
import _groupBy from 'lodash/groupBy';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';
import _isNumber from 'lodash/isNumber';
import _keys from 'lodash/keys';
import _some from 'lodash/some';
import _last from 'lodash/last';
import _findLast from 'lodash/findLast';
import _flatMapDeep from 'lodash/flatMapDeep';
import _flatMap from 'lodash/flatMap';
import _uniqBy from 'lodash/uniqBy';
import _split from 'lodash/split';
import _indexOf from 'lodash/indexOf';
import _trimEnd from 'lodash/trimEnd';
import _endsWith from 'lodash/endsWith';
import _mapValues from 'lodash/mapValues';
import gradientTypes from 'utils/constants/gradientTypes';
import removeHtmlTags from 'utils/removeHtmlTags';
import formatGroupStructure from 'utils/formatGroupStructure';
import formatExaminationTime from 'utils/formatExaminationTime';
import getMedianValue from 'utils/getMedianValue';
import getExaminationDataColor from 'utils/getExaminationDataColor';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import formatFullName from 'utils/formatFullName';
import getDateFromApi from 'utils/getDateFromApi';
import { formatStatusNote } from 'utils/formatNotes';
import config from 'config';

const { comparisonMethods } = config;

export const graphElementDefaultProperties = [
  'code',
  'dimensionName',
  'expandable',
  'isExpanded',
  'isInverted',
  'isPlain',
  'level',
  'withExternalMotivationTooltip',
];

const dimensionLevels = {
  primary: 0,
  secondary: 1,
  tertiary: 2,
};

const standardizedUpperLimitsMap = {
  1: 'upper_limit_sten',
  2: 'upper_limit_stanin',
  3: 'upper_limit_ten',
  4: 'upper_limit_percentile',
};

const standardizedScalesDataKeys = {
  1: 'sten',
  2: 'stanin',
  3: 'ten',
  4: 'percentile',
};

const valueTypes = {
  raw: 'raw',
  rounded: 'rounded',
  confidenceInterval: 'confidence_interval',
};

const getValuePath = (dataKey, type) => {
  if (!_includes(_values(valueTypes), type)) {
    return '';
  }

  return `standardized_results.${type}.${dataKey}`;
};

const getPercentageTime = (resultTime, averageTime) => {
  const result = (resultTime / (averageTime * 2)) * 100;

  return Math.min(result, 100);
};

const formatTimeData = (surveyData, sessionData) => {
  const minimumTime = _get(surveyData, 'min_session_time', 0);
  const patientTime = _get(sessionData, 'session_duration', null);
  const averageTime = _get(surveyData, 'average_session_time', 0);

  return ({
    minimumTime: {
      time: formatExaminationTime(minimumTime),
      scaleTime: getPercentageTime(minimumTime, averageTime),
    },
    patientTime: {
      time: formatExaminationTime(patientTime),
      scaleTime: getPercentageTime(patientTime, averageTime),
    },
    avarageTime: {
      time: formatExaminationTime(averageTime),
      scaleTime: getPercentageTime(averageTime, averageTime),
    },
    isAboveMinimum: patientTime > minimumTime,
  });
};

const formatScaleData = (scale) => ({
  id: scale.id,
  name: scale.name,
  code: scale.code,
  order: scale.order,
  level: scale.level,
  description: scale.description,
});

const formatDimensionCodes = (scales, loads, parameters) => formatGroupStructure(
  scales,
  loads,
  parameters,
  formatScaleData,
);

const formatDimensionDescription = (scale, surveyEvaluationData) => {
  const evaluation = _find(
    surveyEvaluationData,
    (data) => data.scale_parameter.scale_id === scale.id,
  );

  return ({
    id: scale.id,
    name: scale.name,
    code: scale.code,
    order: scale.order,
    level: scale.level,
    overallResult:
      evaluation
        .standard_categorical_scale_value_description
        .standard_categorical_scale_value
        .label,
    standarizedScaleResult:
      _mapValues(
        standardizedScalesDataKeys,
        (value) => _get(evaluation, getValuePath(value, valueTypes.rounded)),
      ),
    description: evaluation.standard_categorical_scale_value_description.description,
  });
};

const formatDescriptionData = (
  scales,
  loads,
  parameters,
  surveyEvaluationData,
  sessionData,
) => ({
  summary: _get(sessionData, 'scs_values_combinations_set.description', ''),
  dimensionData: formatGroupStructure(
    scales,
    loads,
    parameters,
    (scale) => formatDimensionDescription(scale, surveyEvaluationData),
  ),
});

const formatExaminationSymbol = (researchToolData, sessionData) => (
  `${getDateFromApi(sessionData.started_at)} - ${researchToolData.code}`
);

const getReferenceLines = (sortedScaleValues, selectedScale) => {
  const referenceLines = ({
    high: _get(_findLast(sortedScaleValues, (value) => value.results_zone === 'MEDIUM'), standardizedUpperLimitsMap[selectedScale]),
    medium: getMedianValue(selectedScale),
    low: _get(_findLast(sortedScaleValues, (value) => value.results_zone === 'LOW'), standardizedUpperLimitsMap[selectedScale]),
  });
  const hasVeryLowValue = _find(sortedScaleValues, (value) => value.results_zone === 'VERY_LOW');
  const hasVeryHighValue = _find(sortedScaleValues, (value) => value.results_zone === 'VERY_HIGH');

  if (hasVeryHighValue) {
    referenceLines.veryHigh = _get(_findLast(sortedScaleValues, (value) => value.results_zone === 'HIGH'), standardizedUpperLimitsMap[selectedScale]);
  }

  if (hasVeryLowValue) {
    referenceLines.veryLow = _get(_findLast(sortedScaleValues, (value) => value.results_zone === 'VERY_LOW'), standardizedUpperLimitsMap[selectedScale]);
  }

  return referenceLines;
};

const getCodeAfterLastDash = (code) => _last(_split(code, '-'));

const getEvaluationValue = (value, selectedScale) => {
  if (_includes(['sten', 'stanin'], selectedScale)) {
    const scaleRoundingFactor = config.scaleRoundingFactors[selectedScale];
    const baseValue = Math.floor(value);
    const decimal = value - baseValue;
    if (decimal < 0.5) {
      return baseValue + Math.min(decimal, 0.5 - scaleRoundingFactor);
    }
    return baseValue + Math.max(decimal, 0.5 + scaleRoundingFactor);
  }
  return value;
};

const getGraphDataElement = (
  dimensionData,
  expandable,
  groupedSurveyEvaluations,
  expandedDimensions,
  selectedScale,
  dataKeyPrefix,
  isComparing,
  useCodeAfterLastDash,
  scalesData,
  shouldBeExpanded,
  isVertical,
  onlyPrimaryDimension,
  isGroupSurvey = false,
) => {
  const code = useCodeAfterLastDash ? getCodeAfterLastDash(dimensionData.code) : dimensionData.code;
  const dimensionName = dimensionData.name;
  const level = dimensionData.level;
  const isExpanded = _includes(expandedDimensions, dimensionData.code)
    || isComparing
    || shouldBeExpanded;
  const scaleData = _find(scalesData, (scale) => scale.id === dimensionData.id);
  const isInverted = scaleData.gradient_type === gradientTypes.reversedGradient;
  const isPlain = scaleData.gradient_type === gradientTypes.withoutGradient;
  const withExternalMotivationTooltip = code === config.externalMotivationCode && isVertical;

  const hideConfidenceInterval = (level === 0
    && _some(scalesData, (scale) => scale.order > 0 || scale.order === null))
  && !onlyPrimaryDimension;
  let newDataElement = ({
    code,
    dimensionName,
    expandable,
    isExpanded,
    level,
    isInverted,
    isPlain,
    withExternalMotivationTooltip,
  });

  _forEach(groupedSurveyEvaluations, (resultsGroup, resultsGroupIndex) => {
    const name = `${dataKeyPrefix}-${resultsGroupIndex}`;
    const evaluation = _find(
      resultsGroup,
      (result) => {
        const scaleCode = _get(result, 'scale_parameter.scale.code', null);

        return (
          useCodeAfterLastDash ? (
            getCodeAfterLastDash(scaleCode) === getCodeAfterLastDash(dimensionData.code)
          ) : (
            scaleCode === dimensionData.code
          )
        );
      },
    );
    const selectedScaleDataKey = standardizedScalesDataKeys[selectedScale];
    const valuePath = getValuePath(selectedScaleDataKey, valueTypes.raw);
    const roundedValuePath = getValuePath(selectedScaleDataKey, valueTypes.rounded);
    const confidenceIntervalPath = getValuePath(
      selectedScaleDataKey,
      valueTypes.confidenceInterval,
    );
    const confidenceInterval = hideConfidenceInterval
      ? null
      : _get(evaluation, confidenceIntervalPath);
    const rawValueKey = name;
    const roundedValueKey = `${name}_rounded`;
    const zoneKey = `${name}_zone`;
    const confidenceIntervalKey = `${name}_confidenceInterval`;

    newDataElement = {
      ...newDataElement,
      [rawValueKey]: isGroupSurvey
        ? _get(evaluation, roundedValuePath, '')
        : getEvaluationValue(
          _get(evaluation, valuePath, ''),
          selectedScaleDataKey,
        ),
      [roundedValueKey]: _get(evaluation, roundedValuePath, ''),
      [confidenceIntervalKey]: confidenceInterval,
      [zoneKey]: _get(
        evaluation,
        'standard_categorical_scale_value_description.standard_categorical_scale_value.results_zone',
        null,
      ),
    };
  });
  return newDataElement;
};

const getDataKeys = (
  groupedSurveyEvaluations,
  dataKeyPrefix,
  dimensionLevel,
  sessionsData,
) => _map(
  groupedSurveyEvaluations,
  (group, index) => {
    const dataKeyName = `${dataKeyPrefix}-${index}`;
    const sessionPatientId = group[0].session_patient_id;
    const examinationData = _find(
      sessionsData,
      (session) => session.id === sessionPatientId,
    );
    const examinationDate = getDateFromApi(examinationData.started_at);
    const testName = _get(examinationData, 'session.survey_tool.name', '');

    return ({
      name: dataKeyName,
      date: examinationDate,
      level: dimensionLevel,
      color: getExaminationDataColor(index),
      sessionPatientId,
      testName,
    });
  },
);

const groupBySessionPatient = (evaluations, examinationDates) => {
  const groupedBySessionPatient = _values(
    _groupBy(evaluations,
      (el) => el.session_patient_id || el.sessionPatientId),
  );

  const sortedByComparisonOrder = _sortBy(
    groupedBySessionPatient,
    (group) => _indexOf(
      examinationDates,
      _get(group, '[0].session_patient_id')
      || _get(group, '[0].sessionPatientId'),
    ),
  );

  return sortedByComparisonOrder;
};

const formatGraphData = (
  standardScaleData,
  surveyEvaluationData,
  dimensionGroups,
  expandedDimensions,
  filterValues,
  sessionsToCompare,
  batteryCode,
  scalesData,
  isGroupSurvey = false,
) => {
  const sortedScaleValues = _sortBy(standardScaleData.standard_categorical_scale_values, 'value');
  const isComparing = !_isEmpty(filterValues.examinationsDates);

  const selectedScale = filterValues.standarizedScale;
  const groupedSurveyEvaluations = groupBySessionPatient(
    surveyEvaluationData,
    filterValues.examinationsDates,
  );
  const isVertical = filterValues.comparisonMethod === 'vertical';
  const useCodeAfterLastDash = (
    isVertical
    && isComparing
    && batteryCode === config.batteryWithCodesToShorten
  );

  const graphDataElements = [];
  const primaryDataKeyPrefix = 'primary';
  let dataKeys = getDataKeys(
    groupedSurveyEvaluations,
    primaryDataKeyPrefix,
    dimensionLevels.primary,
    sessionsToCompare,
  );

  _forEach(dimensionGroups, (group, groupIndex) => {
    const { primaryDimension, secondaryDimensions } = group;
    const isPrimaryExpandable = !_isEmpty(secondaryDimensions)
     && !!filterValues.secondaryDimension
     && !isComparing;
    const forcePrimaryExpanded = (
      filterValues.secondaryDimension || filterValues.tertiaryDimension
    ) && !filterValues.primaryDimension;
    const primaryDataElement = getGraphDataElement(
      primaryDimension,
      isPrimaryExpandable,
      groupedSurveyEvaluations,
      expandedDimensions,
      selectedScale,
      primaryDataKeyPrefix,
      isComparing,
      useCodeAfterLastDash,
      scalesData,
      forcePrimaryExpanded,
      isVertical,
      _isEmpty(secondaryDimensions),
      isGroupSurvey,
    );

    graphDataElements.push(primaryDataElement);

    if (primaryDataElement.isExpanded) {
      const secondaryDataKeyPrefix = `primary-${groupIndex}-group`;
      dataKeys = [
        ...dataKeys,
        ...getDataKeys(
          groupedSurveyEvaluations,
          secondaryDataKeyPrefix,
          dimensionLevels.secondary,
          sessionsToCompare,
        ),
      ];

      _forEach(secondaryDimensions, (secondaryDimension, secondaryDimensionIndex) => {
        const { tertiaryDimensions } = secondaryDimension;
        const isSecondaryExpandable = !_isEmpty(tertiaryDimensions)
          && !!filterValues.tertiaryDimension
          && !isComparing;
        const forceSecondaryExpanded = filterValues.tertiaryDimension
          && !filterValues.secondaryDimension && !filterValues.primaryDimension;
        const secondaryDataElement = getGraphDataElement(
          secondaryDimension,
          isSecondaryExpandable,
          groupedSurveyEvaluations,
          expandedDimensions,
          selectedScale,
          secondaryDataKeyPrefix,
          isComparing,
          useCodeAfterLastDash,
          scalesData,
          forceSecondaryExpanded,
          isVertical,
          _isEmpty(secondaryDimensions),
          isGroupSurvey,
        );
        graphDataElements.push(secondaryDataElement);

        if (secondaryDataElement.isExpanded) {
          const teriaryDataKeyPrefix = `primary-${groupIndex}-secondary-${secondaryDimensionIndex}-group`;
          dataKeys = [
            ...dataKeys,
            ...getDataKeys(
              groupedSurveyEvaluations,
              teriaryDataKeyPrefix,
              dimensionLevels.tertiary,
              sessionsToCompare,
            ),
          ];

          _forEach(tertiaryDimensions, (tertiaryDimension) => {
            const tertiaryDataElement = getGraphDataElement(
              tertiaryDimension,
              false,
              groupedSurveyEvaluations,
              expandedDimensions,
              selectedScale,
              teriaryDataKeyPrefix,
              isComparing,
              useCodeAfterLastDash,
              scalesData,
              false,
              isVertical,
              _isEmpty(secondaryDimensions),
              isGroupSurvey,
            );
            graphDataElements.push(tertiaryDataElement);
          });
        }
      });
    }
  });

  return ({
    referenceLines: getReferenceLines(sortedScaleValues, selectedScale),
    data: graphDataElements,
    dataKeys,
  });
};

const filterDimensionLevel = (graphData, level) => _filter(
  graphData,
  (dataElement) => dataElement.level !== level,
);

const filterOtherDimensionLevels = (graphData, level) => _filter(
  graphData,
  (dataElement) => dataElement.level === level,
);

const filterByQuality = (graphData, conditionCallback) => _map(
  graphData,
  (dataElement) => ({
    ..._pickBy(
      dataElement,
      (value, name) => (
        _isNumber(value) && (conditionCallback(value) || conditionCallback(dataElement[_trimEnd(name, '_rounded')]))
      ) || _endsWith(name, '_zone')
       || _endsWith(name, '_confidenceInterval'),
    ),
    ..._pick(
      dataElement,
      graphElementDefaultProperties,
    ),
  }),
);

const filterGraphData = (graphData, referenceLines, filterValues) => {
  let newGraphData = graphData;
  const isComparing = !_isEmpty(filterValues.examinationsDates);

  if (!isComparing) {
    if (!filterValues.primaryDimension) {
      newGraphData = filterDimensionLevel(graphData, dimensionLevels.primary);
    }

    if (!filterValues.secondaryDimension) {
      newGraphData = filterDimensionLevel(newGraphData, dimensionLevels.secondary);
    }

    if (!filterValues.tertiaryDimension) {
      newGraphData = filterDimensionLevel(newGraphData, dimensionLevels.tertiary);
    }
  } else {
    if (filterValues.dimensionTypeFilter === 'primaryDimension') {
      newGraphData = filterOtherDimensionLevels(graphData, dimensionLevels.primary);
    }

    if (filterValues.dimensionTypeFilter === 'secondaryDimension') {
      newGraphData = filterOtherDimensionLevels(graphData, dimensionLevels.secondary);
    }

    if (filterValues.dimensionTypeFilter === 'tertiaryDimension') {
      newGraphData = filterOtherDimensionLevels(graphData, dimensionLevels.tertiary);
    }
  }

  if (filterValues.qualityFilter === 'showHighOnly') {
    newGraphData = filterByQuality(
      newGraphData,
      (value) => _isNumber(value) && value >= referenceLines.high,
    );
  } else if (filterValues.qualityFilter === 'showLowOnly') {
    newGraphData = filterByQuality(
      newGraphData,
      (value) => _isNumber(value) && value <= referenceLines.low,
    );
  } else if (filterValues.qualityFilter === 'showHighAndLow') {
    newGraphData = filterByQuality(
      newGraphData,
      (value) => _isNumber(value) && (
        value <= referenceLines.low || value >= referenceLines.high
      ),
    );
  }

  return newGraphData;
};

const formatOptionName = (sessionPatient, withName, isGroup) => {
  const date = getDateFromApi(
    isGroup ? sessionPatient.last_session_patient_end_at : sessionPatient.started_at,
  );
  const name = _get(sessionPatient, 'session.survey_tool.name', null);

  return withName ? `${date} - ${name}` : date;
};

const formatOptions = (sessionPatients, withName, isGroup) => _map(
  sessionPatients,
  (sessionPatient) => ({
    id: sessionPatient.id,
    name: formatOptionName(sessionPatient, withName, isGroup),
  }),
);

const filterResultsToCompare = (sessionsByBattery, sessionPatientId) => _filter(
  sessionsByBattery,
  (session) => session.session_patient_id !== sessionPatientId,
);

const formatComparisonOptions = (
  sessionsByBattery,
  selectedExaminationToolId,
  sessionPatientId,
  isGroupComparison = false,
) => {
  const otherExaminations = _filter(
    sessionsByBattery,
    (sessionPatient) => sessionPatient.id !== sessionPatientId,
  );

  const groupedExaminations = _groupBy(
    otherExaminations,
    (el) => isGroupComparison || _get(el, 'session.survey_tool_id', false) === selectedExaminationToolId,
  );
  const verticalFilterOptions = formatOptions(groupedExaminations.false, true, isGroupComparison);
  const horizontalFilterOptions = formatOptions(groupedExaminations.true, false, isGroupComparison);

  return {
    verticalFilterOptions,
    horizontalFilterOptions,
  };
};

const getDataWithComparingResults = (initialData, dataToCompare, filterValues) => {
  const resultsToCompare = _filter(
    dataToCompare,
    (result) => _includes(filterValues.examinationsDates, result.session_patient_id),
  );

  return [...initialData, ...resultsToCompare];
};

const getTableRow = (
  sessionData,
  referenceLines,
  dataKeys,
  isMain,
) => _map(sessionData, (evaluation) => {
  const dataKey = _find(
    _keys(evaluation), (key) => _some(
      dataKeys,
      (examinationDataKey) => examinationDataKey.name === key,
    ),
  );
  const dataValue = evaluation[`${dataKey}_rounded`];
  const confidenceInterval = evaluation[`${dataKey}_confidenceInterval`];
  const plainValue = evaluation[`${dataKey}`];
  const { isInverted, isPlain } = evaluation;

  let cell = {
    isAccented: plainValue > referenceLines.high || plainValue < referenceLines.low,
    value: dataValue,
    zone: evaluation[`${dataKey}_zone`],
    isInverted,
    isPlain,
  };

  if (isMain) {
    cell = {
      ...cell,
      name: evaluation.code,
      extendedName: evaluation.dimensionName,
      isExpandable: evaluation.expandable,
      isExpanded: evaluation.isExpanded,
      withBoldText: !evaluation.level,
      confidenceInterval,
    };
  }

  return cell;
});

const getTableData = (sessionsData, sessionPatientId, filterValues) => {
  const selectedExaminationDataKeys = [];
  const otherExaminationsDataKeys = [];

  const { dataKeys, referenceLines } = sessionsData;

  _forEach(dataKeys, (el) => {
    if (el.sessionPatientId === sessionPatientId) {
      selectedExaminationDataKeys.push(el);
    } else {
      otherExaminationsDataKeys.push(el);
    }
  });

  const result = {
    date: selectedExaminationDataKeys[0].date,
    color: selectedExaminationDataKeys[0].color,
    data: getTableRow(
      sessionsData.data,
      referenceLines,
      selectedExaminationDataKeys,
      true,
    ),
  };

  const groupedEvaluationsKeys = groupBySessionPatient(
    otherExaminationsDataKeys,
    filterValues.examinationsDates,
  );

  const referenceResults = _map(groupedEvaluationsKeys, (keyGroup) => ({
    date: keyGroup[0].date,
    color: keyGroup[0].color,
    data: getTableRow(
      sessionsData.data,
      referenceLines,
      keyGroup,
      false,
    ),
  }));

  return ({
    result,
    referenceResults,
  });
};

const formatExaminationHistory = (sessionData) => {
  const examinationHistory = _get(sessionData, 'session_patient_status_notes', []);

  return _map(examinationHistory, (el) => ({
    id: el.id,
    date: getDateTimeFromApi(el.paused_at || el.updated_at),
    diagnostician: formatFullName(el.user),
    status: el.status,
    note: el.note ? formatStatusNote(el) : null,
  }));
};

const formatReportData = (sessionData, userData) => ({
  date: getDateTimeFromApi(sessionData.ended_at),
  diagnosticianName: formatFullName(_last(sessionData.session_patient_status_notes).user),
  clinicName: _get(userData, 'clinics[0].name', ''),
});

const getAllDimensionsCodes = (dimensions) => _map(dimensions, (dimension) => dimension.code);

const flattenGroups = (groups) => _flatMapDeep(
  groups,
  (group) => ([
    group.primaryDimension,
    _flatMap(
      group.secondaryDimensions,
      (secondaryDimension) => [secondaryDimension, secondaryDimension.tertiaryDimensions],
    ),
  ]),
);

const formatDescriptionReportData = (dimensionsData) => _map(
  flattenGroups(dimensionsData),
  (dimensionData) => ({
    id: dimensionData.id,
    name: dimensionData.name,
    code: dimensionData.code,
    overallResult: dimensionData.overallResult,
    description: removeHtmlTags(dimensionData.description),
    sten: dimensionData.standarizedScaleResult[1],
    stanin: dimensionData.standarizedScaleResult[2],
    ten: dimensionData.standarizedScaleResult[3],
    percentile: dimensionData.standarizedScaleResult[4],
  }),
);

const getResearchToolScales = (scales) => _sortBy(_uniqBy(scales, 'code'), ['survey_tool_order', 'order']);

const getDefaultComparisonMethod = (horizontalFilterOptions, verticalFilterOptions) => (
  (_isEmpty(horizontalFilterOptions) && !_isEmpty(verticalFilterOptions))
    ? comparisonMethods.vertical
    : comparisonMethods.horizontal
);

export {
  formatTimeData,
  formatDimensionCodes,
  formatGraphData,
  filterGraphData,
  filterResultsToCompare,
  formatComparisonOptions,
  getDataWithComparingResults,
  getTableData,
  formatDescriptionData,
  formatExaminationSymbol,
  formatExaminationHistory,
  formatReportData,
  getAllDimensionsCodes,
  formatDescriptionReportData,
  getResearchToolScales,
  getDefaultComparisonMethod,
};
