import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _isEqual from 'lodash/isEqual';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

import { historyShape, locationShape } from 'utils/shapes/router.shapes';

import {
  loadUserData, clearPagingData, loadGlobalData, ping,
  showVersionPopup, hideVersionPopup,
} from 'containers/store';
import config from 'config';

const unauthorizedRoutes = ['/login', '/forgotPassword', '/resetPassword/.+', '/confirmLogin/.+', '/tokenLogin/.+', '/createPassword/.+', '/$'];

class Global extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    clearPagingData: PropTypes.func.isRequired,
    hideVersionPopup: PropTypes.func.isRequired,
    history: historyShape.isRequired,
    loadGlobalData: PropTypes.func.isRequired,
    loadUserData: PropTypes.func.isRequired,
    location: locationShape.isRequired,
    ping: PropTypes.func.isRequired,
    showVersionPopup: PropTypes.func.isRequired,
    globalData: PropTypes.shape({
      standardizedScales: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
      })),
    }),
    lastUsedAppVersion: PropTypes.string,
  };

  static defaultProps = {
    lastUsedAppVersion: '',
    globalData: {},
  }

  constructor(props) {
    super(props);

    this.onHistoryChange(props.history.location, 'PUSH');

    this.state = {
      userDataLoaded: false,
    };
  }

  async componentDidMount() {
    window.addEventListener('storage', (event) => {
      if (
        event.key === config.localStorageLoginKey
        && event.newValue !== event.oldValue
      ) {
        this.redirectToLogin();
      }
    });

    let isUnauthorized = false;

    _forEach(unauthorizedRoutes, (el) => {
      const regexp = new RegExp(el);
      if (this.props.location.pathname.match(regexp)) {
        isUnauthorized = true;
      }
    });

    if (isUnauthorized) {
      await this.props.ping();
      this.setDataLoaded();
    } else {
      try {
        await this.props.loadUserData();
        await this.props.loadGlobalData();
      } catch (error) {
        this.setDataLoaded();
      }
    }

    if (config.appVersion !== this.props.lastUsedAppVersion) {
      this.props.showVersionPopup();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line
    // TODO: refactor
    if (this.props.location.pathname !== nextProps.location.pathname
      || !_isEqual(this.props.location.state, nextProps.location.state)) {
      this.onHistoryChange(nextProps.location, nextProps.history.action);
    }
  }

  componentDidUpdate() {
    if (!this.state.userDataLoaded && !_isEmpty(this.props.globalData)) {
      this.setDataLoaded();
    }

    if (config.appVersion !== this.props.lastUsedAppVersion) {
      this.props.showVersionPopup();
    } else {
      this.props.hideVersionPopup();
    }
  }

  componentDidCatch(err) {
    if (typeof Sentry !== 'undefined' && process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
      Sentry.captureException(err);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage');
  }

  setDataLoaded = () => {
    this.setState({
      userDataLoaded: true,
    });
  }

  onHistoryChange = () => {
    this.props.clearPagingData();
  };

  redirectToLogin = () => {
    window.location.pathname = '/';
  };

  render() {
    return this.state.userDataLoaded ? this.props.children : null;
  }
}

const mapDispatchToProps = {
  loadUserData,
  loadGlobalData,
  clearPagingData,
  ping,
  showVersionPopup,
  hideVersionPopup,
};

const mapStateToProps = (state) => ({
  ...state.ResetPassword,
  lastUsedAppVersion: state.Global.userData.last_used_app_version,
  globalData: state.Global.globalData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Global));
