import { push } from 'connected-react-router';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _without from 'lodash/without';
import _every from 'lodash/every';
import _includes from 'lodash/includes';
import _find from 'lodash/find';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _orderBy from 'lodash/orderBy';
import _values from 'lodash/values';
import _flatten from 'lodash/flatten';
import _some from 'lodash/some';
import _mapValues from 'lodash/mapValues';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

import getCountStartFrom from 'utils/getCountStartFrom';
import getSortAndPageData from 'utils/getSortAndPageData';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getParamsFromQuery from 'utils/getParamsFromQuery';
import createURLWithQuery from 'utils/createURLWithQuery';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import errorCatch from 'utils/errorCatch';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import convertSecondsToMinutes from 'utils/convertSecondsToMinutes';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import getExaminationStatus from 'utils/getExaminationStatus';

import {
  hideLoader,
  showLoader,
  showTransparentLoader,
  setPage,
  setRowsPerPage,
  setLastFilterValues,
  setSortingData,
  setSortingAndPagination,
  showSnackbar,
} from 'containers/store';
import { loadPatients } from 'containers/Patients/store';
import { getPatientClassAndSection } from 'containers/Patients/utils';

import { mapSurveyToolIds, getPatientName } from './utils';

const defaultSorting = {
  fieldName: 'lastResearch',
  sortDirection: 'desc',
};

const sortFieldsMapping = {
  patient: 'last_name',
  lastResearch: 'updated_at',
  status: 'status',
  classAndSection: 'class_and_section',
};

const sortBatteryFieldsMapping = {
  name: 'name',
  avarageSurveyTime: 'avarageSurveyTime',
};

const defaultBatterySorting = {
  sortingField: 'name',
  sortDirection: 'desc',
};

export const mapPatients = (
  patients,
  startingPosition,
  patientsNotMatchingCriteria = [],
) => patients.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  patient: getPatientName(el, !_includes(patientsNotMatchingCriteria, el.id)),
  classAndSection: getPatientClassAndSection(_get(el, 'school_class', null), _get(el, 'school_class_section', null)),
  lastResearch: _get(el, 'latest_session_patient.updated_at', null)
    ? getDateTimeFromApi(_get(el, 'latest_session_patient.updated_at'))
    : '',
  status: getExaminationStatus(_get(el, 'latest_session_patient', {})),
  deletedAt: el.deleted_at,
}));

const mapBatteries = (batteries) => _map(batteries, (battery) => ({
  key: battery.id,
  id: battery.id,
  name: `${battery.code} - ${battery.name}`,
  code: battery.code,
  surveyTools: _map(battery.survey_tools, (tool) => ({
    key: tool.id,
    id: tool.id,
    name: `${tool.code} - ${tool.name}`,
    description: tool.description,
    code: tool.code,
    avarageSurveyTime: tool.survey.average_session_time,
  })),
  sortProps: {
    sortingField: battery.sortingField,
    sortDirection: battery.sortDirection,
  },
}));

const getSurveyCodes = (batteries, selectedSurveys) => {
  let selectedSurveysCodes = '';
  const codes = [];
  _forEach(selectedSurveys, (surveys, batteryId) => {
    const battery = _find(batteries, (b) => b.id === parseInt(batteryId));

    _forEach(surveys, (survey) => {
      const selectedSurvey = _find(battery.surveyTools, (tool) => tool.id === survey);
      codes.push(selectedSurvey.code);
    });
  });

  _forEach(_uniq(codes), (code) => {
    if (selectedSurveysCodes === '') {
      selectedSurveysCodes += `${code}`;
      return;
    }
    selectedSurveysCodes += `, ${code}`;
  });

  return selectedSurveysCodes;
};

const getAvarageSurveyTime = (batteries, selectedSurveys) => {
  let avarageSurveyTime = 0;
  _forEach(selectedSurveys, (surveys, batteryId) => {
    const battery = _find(batteries, (b) => b.id === parseInt(batteryId));

    _forEach(surveys, (survey) => {
      const selectedSurvey = _find(battery.surveyTools, (tool) => tool.id === survey);
      avarageSurveyTime += selectedSurvey.avarageSurveyTime;
    });
  });

  return avarageSurveyTime;
};

export const initialState = {
  isLoadedPage: false,
  isAnonymous: false,
  surveyDetails: {
    name: '',
    isNameChangedByUser: false,
    participantAmount: 0,
    class: '',
    section: '',
    avarageSurveyTime: 0,
    selectedTests: '',
  },
  surveyId: null,
  anonymousPatientNamePrefix: '',
  patients: [],
  selectedPatients: [],
  selectedAllPatients: false,
  batteries: [],
  selectedSurveyTools: {},
  deleteDialogStatus: false,
  patientsNotMatchingCriteria: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_SURVEY_CONFIGURATION/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_DATA: 'GROUP_SURVEY_CONFIGURATION/LOAD_PAGE_DATA',
  CHANGE_EXAMINATION_NAME: 'GROUP_SURVEY_CONFIGURATION/CHANGE_EXAMINATION_NAME',
  CHANGE_EXAMINATION_DETAIL: 'GROUP_SURVEY_CONFIGURATION/CHANGE_DETAIL',
  SELECT_PATIENT: 'GROUP_SURVEY_CONFIGURATION/SELECT_PATIENT',
  SELECT_ALL_PATIENTS: 'GROUP_SURVEY_CONFIGURATION/SELECT_ALL_PATIENTS',
  SET_FILTERED_PATIENTS: 'GROUP_SURVEY_CONFIGURATION/SET_FILTERED_PATIENTS',
  SET_QUERY_PARAMS: 'GROUP_SURVEY_CONFIGURATION/SET_QUERY_PARAMS',
  SELECT_SURVEY_TOOL: 'GROUP_SURVEY_CONFIGURATION/SELECT_SURVEY_TOOL',
  SELECT_ALL_BATTERY_TOOLS: 'GROUP_SURVEY_CONFIGURATION/SELECT_ALL_BATTERY_TOOLS',
  CHANGE_BATTERY_SORT: 'GROUP_SURVEY_CONFIGURATION/CHANGE_BATTERY_SORT',
  SET_DELETE_DIALOG_STATUS: 'GROUP_SURVEY_CONFIGURATION/SET_DELETE_DIALOG_STATUS',
  REFRESH_SURVEY_ID: 'GROUP_SURVEY_CONFIGURATION/REFRESH_SURVEY_ID',
  CLEAR_STORE: 'GROUP_SURVEY_CONFIGURATION/CLEAR_STORE',
  GET_PATIENTS_NOT_MATCHING_CRITERIA: 'GROUP_SURVEY_CONFIGURATION/GET_PATIENTS_NOT_MATCHING_CRITERIA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const batteriesWithProps = _map(action.responses.batteriesData.data.items, (battery) => ({
        ...battery,
        ...defaultBatterySorting,
      }));
      const batteries = mapBatteries(batteriesWithProps);
      const surveyInfo = action.surveyInfo;

      const selectedSurveyTools = {};
      _forEach(batteries, (battery) => {
        selectedSurveyTools[battery.id] = [];
      });

      const surveyInfoSelectedSurveyTools = _get(surveyInfo, 'selectedSurveyTools', {});

      const mappedIds = _mapValues(
        action.responses.patientsNotMatchingCriteria.data,
        (val) => !_some(val, (v) => !v),
      );
      const patientsNotMatchingCriteria = _map(
        _keys(_pickBy(mappedIds, (value) => !value)),
        (key) => parseInt(key),
      );

      return {
        ...state,
        ...surveyInfo,
        surveyDetails: {
          ...surveyInfo.surveyDetails,
          avarageSurveyTime: convertSecondsToMinutes(
            getAvarageSurveyTime(batteries, surveyInfoSelectedSurveyTools),
            true,
            true,
          ),
          selectedTests: getSurveyCodes(batteries, surveyInfoSelectedSurveyTools),
        },
        isLoadedPage: true,
        patients: mapPatients(
          action.responses.patientData.data.items,
          action.startingPosition,
          state.patientsNotMatchingCriteria,
        ),
        batteries,
        selectedSurveyTools: {
          ...selectedSurveyTools,
          ...surveyInfoSelectedSurveyTools,
        },
        totalItemsCount: action.responses.patientData.data.total,
        anonymousPatientNamePrefix: action.anonymousPatientNamePrefix,
        patientsNotMatchingCriteria,
      };
    }

    case actionTypes.CHANGE_EXAMINATION_NAME: {
      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          name: action.response,
          isNameChangedByUser: true,
        },
      };
    }

    case actionTypes.CHANGE_EXAMINATION_DETAIL: {
      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          [action.response.key]: action.response.value,
        },
      };
    }

    case actionTypes.SELECT_PATIENT: {
      const { value, id } = action.response;
      const newSelectedPatients = value
        ? [...state.selectedPatients, id]
        : _without(state.selectedPatients, id);

      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          participantAmount: value
            ? state.surveyDetails.participantAmount + 1
            : state.surveyDetails.participantAmount - 1,
        },
        patients: state.patients,
        selectedPatients: newSelectedPatients,
        selectedAllPatients: _every(
          state.patients,
          (patient) => _includes(newSelectedPatients, patient.apiId),
        ),
      };
    }

    case actionTypes.SELECT_ALL_PATIENTS: {
      const uniqIds = _uniq([
        ...state.selectedPatients,
        ..._map(state.patients, (patient) => patient.apiId),
      ]);

      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          participantAmount: action.response.value
            ? uniqIds.length
            : 0,
        },
        selectedPatients: action.response.value ? uniqIds : [],
        selectedAllPatients: action.response.value,
      };
    }

    case actionTypes.SET_FILTERED_PATIENTS: {
      let selectedAllPatients = true;
      if (!_every(_map(action.response.data.items, 'id'), (id) => _includes(state.selectedPatients, id))) {
        selectedAllPatients = false;
      }

      if (_isEmpty(action.response.data.items)) {
        selectedAllPatients = false;
      }

      return {
        ...state,
        patients: mapPatients(
          action.response.data.items,
          action.startCountFrom,
          state.patientsNotMatchingCriteria,
        ),
        totalItemsCount: action.response.data.total,
        selectedAllPatients,
      };
    }

    case actionTypes.SET_QUERY_PARAMS: {
      return {
        ...state,
        lastQueryParams: action.queryParams,
      };
    }

    case actionTypes.SELECT_SURVEY_TOOL: {
      const { value, batteryId, toolId } = action.response;

      const selectedSurveyTools = {
        ...state.selectedSurveyTools,
        [batteryId]: value
          ? [...state.selectedSurveyTools[batteryId], toolId]
          : _without(
            [...state.selectedSurveyTools[batteryId]],
            toolId,
          ),
      };

      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          name: state.surveyDetails.isNameChangedByUser
            ? state.surveyDetails.name
            : getSurveyCodes(state.batteries, selectedSurveyTools),
          avarageSurveyTime: convertSecondsToMinutes(
            getAvarageSurveyTime(state.batteries, selectedSurveyTools), true, true,
          ),
          selectedTests: getSurveyCodes(state.batteries, selectedSurveyTools),
        },
        selectedSurveyTools,
      };
    }

    case actionTypes.SELECT_ALL_BATTERY_TOOLS: {
      const { value, batteryId, toolIds } = action.response;

      const selectedSurveyTools = {
        ...state.selectedSurveyTools,
        [batteryId]: value
          ? _uniq([...state.selectedSurveyTools[batteryId], ...toolIds])
          : [],
      };

      return {
        ...state,
        selectedSurveyTools,
        surveyDetails: {
          ...state.surveyDetails,
          name: state.surveyDetails.isNameChangedByUser
            ? state.surveyDetails.name
            : getSurveyCodes(state.batteries, selectedSurveyTools),
          avarageSurveyTime: convertSecondsToMinutes(
            getAvarageSurveyTime(state.batteries, selectedSurveyTools), true, true,
          ),
          selectedTests: getSurveyCodes(state.batteries, selectedSurveyTools),
        },
      };
    }

    case actionTypes.CHANGE_BATTERY_SORT: {
      const battery = _find(state.batteries, (b) => b.id === action.batteryId);

      return {
        ...state,
        batteries: _map(state.batteries, (b) => {
          if (b.id === battery.id) {
            return {
              ...battery,
              surveyTools: _orderBy(
                battery.surveyTools,
                [action.sortData.fieldName],
                [action.sortData.sortDirection],
              ),
              sortProps: {
                sortingField: action.sortData.fieldName,
                sortDirection: action.sortData.sortDirection,
              },
            };
          }

          return b;
        }),
      };
    }

    case actionTypes.SET_DELETE_DIALOG_STATUS: {
      return {
        ...state,
        deleteDialogStatus: action.response,
      };
    }

    case actionTypes.REFRESH_SURVEY_ID: {
      return {
        ...state,
        surveyId: action.surveyId,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.GET_PATIENTS_NOT_MATCHING_CRITERIA: {
      const mappedIds = _mapValues(
        action.patientsNotMatchingCriteria,
        (val) => !_some(val, (v) => !v),
      );
      const patientsNotMatchingCriteria = _map(
        _keys(_pickBy(mappedIds, (value) => !value)),
        (key) => parseInt(key),
      );

      return {
        ...state,
        patientsNotMatchingCriteria,
      };
    }

    default: {
      return state;
    }
  }
};

const changeExaminationName = (response) => ({
  type: actionTypes.CHANGE_EXAMINATION_NAME,
  response,
});

const changeExaminationDetail = (response) => ({
  type: actionTypes.CHANGE_EXAMINATION_DETAIL,
  response,
});

const loadPageSuccess = (responses, startingPosition, anonymousPatientNamePrefix, surveyInfo) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
  anonymousPatientNamePrefix,
  surveyInfo,
});

const setFilteredPatients = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_PATIENTS,
  response,
  startCountFrom,
});

const setQueryParams = (queryParams) => ({
  type: actionTypes.SET_QUERY_PARAMS,
  queryParams,
});

const selectPatient = (response) => ({
  type: actionTypes.SELECT_PATIENT,
  response,
});

const selectAllPatients = (response) => ({
  type: actionTypes.SELECT_ALL_PATIENTS,
  response,
});

const selectSurveyTool = (response) => ({
  type: actionTypes.SELECT_SURVEY_TOOL,
  response,
});

const selectAllBatteryTools = (response) => ({
  type: actionTypes.SELECT_ALL_BATTERY_TOOLS,
  response,
});

const changeBatterySort = (batteryId, sortData) => ({
  type: actionTypes.CHANGE_BATTERY_SORT,
  batteryId,
  sortData,
});

const setDeleteDialogStatus = (response) => ({
  type: actionTypes.SET_DELETE_DIALOG_STATUS,
  response,
});

const refreshSurveyId = (surveyId) => ({
  type: actionTypes.REFRESH_SURVEY_ID,
  surveyId,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const getPatientsNotMatchingCriteria = (patientsNotMatchingCriteria) => ({
  type: actionTypes.GET_PATIENTS_NOT_MATCHING_CRITERIA,
  patientsNotMatchingCriteria,
});

export const goToList = () => (dispatch) => {
  dispatch(push('/session_patient_groups'));
};

export const deleteGroupExamination = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const surveyId = getStore().GroupSurveyConfiguration.surveyId;

  ApiManager.request('delete', dispatch, `session_patient_groups/${surveyId}`).then(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.groupSurveyDeleted));
    dispatch(push('/session_patient_groups'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

const loadPatientsNotMatchingCriteria = (
  patients = null, surveyTools = null,
) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const { selectedPatients, selectedSurveyTools } = getStore().GroupSurveyConfiguration;

  const mappedSelectedSurveyTools = _flatten(_values(selectedSurveyTools));

  if (
    (_isEmpty(patients) || _isEmpty(surveyTools))
    && (_isEmpty(selectedPatients) || _isEmpty(mappedSelectedSurveyTools))) {
    return {
      data: {},
    };
  }

  const data = {
    patient_ids: patients || selectedPatients,
    survey_tool_ids: surveyTools || mappedSelectedSurveyTools,
  };

  const url = createURLWithQuery('patients/survey_tools/matching_criteria', data);

  return ApiManager.request('get', dispatch, url);
};

export const saveGroupSurvey = (redirect) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const store = getStore().GroupSurveyConfiguration;
  const clinicId = getStore().Global.userData.clinics[0].id;

  if (!_isEmpty(store.patientsNotMatchingCriteria)) {
    dispatch(showSnackbar(snackbarMessages.patientsDoNotMatchCriteria));
    dispatch(hideLoader());

    return;
  }

  const isEdit = store.surveyId !== null;

  const anonymousData = store.isAnonymous ? {
    is_anonymous: true,
    school_class: store.surveyDetails.class,
    school_class_section: store.surveyDetails.section,
    patients_count: isEdit ? undefined : store.surveyDetails.participantAmount,
  } : {};

  const data = {
    name: store.surveyDetails.name,
    survey_tool_ids: _flatten(_values(store.selectedSurveyTools)),
    patient_ids: store.isAnonymous ? undefined : store.selectedPatients,
    clinic_id: clinicId,
    ...anonymousData,
  };
  let method = 'post';
  let url = 'session_patient_groups';
  const message = snackbarMessages.groupSurveyConfigured;

  if (isEdit) {
    data.id = store.surveyId;
    method = 'put';
    url = `session_patient_groups/${store.surveyId}`;
  }

  ApiManager.request(method, dispatch, url, data).then((response) => {
    dispatch(showSnackbar(message));
    dispatch(refreshSurveyId(response.data.id));

    if (redirect) {
      dispatch(push('/groupExaminationDetails', { id: response.data.id }));
    }
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  });
};

export const openDeleteDialogStatus = () => (dispatch) => {
  dispatch(setDeleteDialogStatus(true));
};

export const closeDeleteDialogStatus = () => (dispatch) => {
  dispatch(setDeleteDialogStatus(false));
};

const loadGroupSurvey = (id) => (dispatch) => {
  const url = `session_patient_groups/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const loadBatteries = () => (dispatch, getStore) => {
  const clinicId = _get(_first(getStore().Global.userData.clinics), 'id');
  const url = createURLWithQuery('batteries', {
    clinicId,
    withSurveyDetails: true,
    hasActiveSurveyTool: true,
    withSurveyToolStatuses: ['ACTIVE'],
    findByBatteryStatusInClinic: 'ACTIVE',
  });

  return ApiManager.request('get', dispatch, url);
};

const getPatients = () => async (dispatch, getStore) => {
  const anonymous = getStore().GroupSurveyConfiguration.isAnonymous;
  if (anonymous) {
    return;
  }

  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  const orderByIdsFirst = getStore().GroupSurveyConfiguration.selectedPatients;

  try {
    const patientData = await dispatch(loadPatients({
      ...data,
      orderByIdsFirst,
      findByManyColumns: data.lastFilterValues.findByManyColumns,
      withoutCreatedForAnonymousSessionPatientGroup: true,
      orderByLatestSessionPatient: true,
    }, 'groupSurveyAddEdit', 'GroupSurveyConfiguration'));
    const patientsNotMatchingCriteria = await dispatch(loadPatientsNotMatchingCriteria());

    dispatch(getPatientsNotMatchingCriteria(patientsNotMatchingCriteria.data));
    dispatch(setFilteredPatients(patientData, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onEditExaminationName = (name) => (dispatch) => {
  dispatch(changeExaminationName(name));
};

export const onEditExaminationDetail = (key, value) => (dispatch) => {
  dispatch(changeExaminationDetail({ key, value }));
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getPatients());
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getPatients());
};

export const setDefaultRowsPerPage = () => (dispatch, getStore) => {
  const rowsPerPageOptions = getStore().Global.pagingData.rowsPerPageOptions;
  dispatch(setRowsPerPage(rowsPerPageOptions[0]));
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const { sortedBy, orderBy } = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, sortedBy, orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getPatients());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getPatients());
};

export const saveQueryParams = () => (dispatch) => {
  const queryParams = getParamsFromQuery(window.location.search);
  dispatch(setQueryParams(queryParams));
};

export const onActionClick = (id) => (dispatch) => {
  dispatch(saveQueryParams());
  dispatch(push('/patientView', { id }));
};

export const onCheck = (id, value) => (dispatch) => {
  dispatch(selectPatient({ id, value }));
  dispatch(getPatients());
};

export const onCheckAll = (value) => (dispatch) => {
  dispatch(selectAllPatients({ value }));
  dispatch(getPatients());
};

export const onSelectSurveyTool = (value, batteryId, toolId) => (dispatch) => {
  dispatch(selectSurveyTool({ value, batteryId, toolId }));
  dispatch(getPatients());
};

export const onSelectAllBatteryTools = (value, batteryId, toolIds) => (dispatch) => {
  dispatch(selectAllBatteryTools({ value, batteryId, toolIds }));
  dispatch(getPatients());
};

export const onChangeBatterySort = (batteryId) => (dispatch, getStore) => (fieldName) => {
  const batteries = getStore().GroupSurveyConfiguration.batteries;
  const battery = _find(batteries, (b) => b.id === batteryId);

  const { sortDirection, sortingField } = battery.sortProps;

  const data = getFieldAndSortDirection(
    fieldName,
    sortDirection,
    sortingField,
    sortBatteryFieldsMapping[fieldName],
  );

  dispatch(changeBatterySort(batteryId, data));
};

export const loadPageData = (
  values, action, anonymousPatientNamePrefix,
) => async (dispatch, getStore) => {
  if (action !== 'POP') {
    dispatch(clearStore());
  }

  const selectedSurveyTools = getStore().GroupSurveyConfiguration.selectedSurveyTools;
  const selectedPatients = getStore().GroupSurveyConfiguration.selectedPatients;

  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      findByManyColumns: values.findByManyColumns || '',
    }));
  }
  dispatch(setQueryParams({}));

  let isAnonymous = false;
  let groupSurvey = null;
  const surveyInfo = {
    isAnonymous: values.anonymous,
    surveyDetails: {
      name: '',
      isNameChangedByUser: false,
      participantAmount: 0,
    },
    selectedSurveyTools,
    selectedPatients,
  };
  try {
    if (values.id) {
      groupSurvey = await dispatch(loadGroupSurvey(values.id));
      const filteredPatients = _filter(
        groupSurvey.data.session_patients,
        (patient) => patient.patient !== null,
      );

      isAnonymous = _get(groupSurvey, 'data.is_anonymous', false);

      surveyInfo.surveyId = _get(groupSurvey, 'data.id');
      surveyInfo.selectedSurveyTools = _isEmpty(selectedSurveyTools)
        ? mapSurveyToolIds(groupSurvey) : selectedSurveyTools;
      surveyInfo.selectedPatients = _isEmpty(selectedPatients)
        ? _uniq(_map(filteredPatients, 'patient_id')) : selectedPatients;
      surveyInfo.isNameChangedByUser = true;
      surveyInfo.isAnonymous = isAnonymous;
      surveyInfo.surveyDetails = {
        name: _get(groupSurvey, 'data.name'),
        isNameChangedByUser: true,
        participantAmount: surveyInfo.selectedPatients.length,
        class: _get(groupSurvey, 'data.school_class', ''),
        section: _get(groupSurvey, 'data.school_class_section', ''),
      };
    }
  } catch (error) {
    errorCatch(error, dispatch);
  }

  if (action === 'POP') {
    const details = getStore().GroupSurveyConfiguration.surveyDetails;
    if (!values.id) {
      surveyInfo.surveyDetails = details;
    }

    if (!_isEmpty(details.name) && values.id) {
      surveyInfo.surveyDetails = details;
    }
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  data.orderByIdsFirst = surveyInfo.selectedPatients;

  const normalExaminationData = isAnonymous ? {
    patientData: { data: { items: [], total: 0 } },
    patientsNotMatchingCriteria: { data: {} },
  } : {
    patientData: dispatch(loadPatients({
      ...data,
      perPage: 30,
      withoutCreatedForAnonymousSessionPatientGroup: true,
      orderByLatestSessionPatient: true,
    }, 'groupSurveyAddEdit', 'GroupSurveyConfiguration')),
    patientsNotMatchingCriteria: dispatch(loadPatientsNotMatchingCriteria(
      surveyInfo.selectedPatients, _flatten(_values(surveyInfo.selectedSurveyTools)),
    )),
  };

  await PromiseAll({
    batteriesData: dispatch(loadBatteries()),
    ...normalExaminationData,
  }).then((responses) => {
    dispatch(setRowsPerPage(30));
    dispatch(getPatientsNotMatchingCriteria(responses.patientsNotMatchingCriteria.data));
    dispatch(loadPageSuccess(responses, startingPosition, anonymousPatientNamePrefix, surveyInfo));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });

  dispatch(hideLoader());
};
