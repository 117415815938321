import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, connect } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history';
import theme from 'theme/theme';
import Routes from 'containers/routes';
import initStore from 'utils/initStore';
import moment from 'moment';
import localization from 'moment/locale/pl';
import VersionManager from 'utils/VersionManager';
import * as serviceWorker from './serviceWorker';
import 'utils/polyfills';

const history = createHistory();
const store = initStore(history);
moment.updateLocale('pl', localization);

class App extends React.PureComponent {
  async componentDidMount() {
    if (typeof Sentry !== 'undefined' && process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
      Sentry.init({ dsn: 'https://e4c7ae03d6714805938e02b1f6d7c24b@sentry.fingoweb.com/17' });
    }

    await VersionManager.init();
    VersionManager.startTimeout();
  }

  componentWillUnmount() {
    VersionManager.resetTimeout();
  }

  render() {
    return (
      <IntlProvider locale="pl" defaultLocale="pl">
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ConnectedRouter history={history}>
              <Routes history={history} />
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App);

ReactDOM.render(
  <Provider store={store}>
    <AppConnected />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
