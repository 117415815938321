import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import grey from '@material-ui/core/colors/grey';

const white = '#fff';
const black = '#000';
const green = '#0AD5B0';
const greenHover = '#0BE5BD';
const borderColor = '#EBEBEF';
const tableBorderColor = '#DCDBE2';
const menuHover = '#292549';
const deepBlue = {
  50: '#7D7A8F',
  60: '#716E87',
};
const noBackgroundButtonTransition = `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;

const theme = createMuiTheme({
  palette: {
    banner: {
      background: '#F9F9F9',
    },
    primary: {
      main: green,
      auxiliary: '#B10058',
      secondAuxiliary: '#2675F2',
      dark: greenHover,
    },
    secondary: { main: '#FFA400' },
    yellow: '#FFD343',
    white,
    deepBlue,
    bonus: {
      main: '#eaafa1',
      light: 'rgba(234, 175, 161, 0.2)',
    },
    grey: {
      light: grey[300],
      medium: grey[400],
      dark: grey[500],
      description: grey[600],
      icon: grey[700],
      background: '#F9F9F9',
      divider: '#E0E0E0',
      hover: '#EAEAED',
      border: '#DCDBE2',
      scrollbarThumb: '#C8C7D1',
      outlinedInputBorder: '#E1E0E6',
    },
    icon: {
      hover: '#E4E4E9',
      groupSurvey: '#0068B1',
    },
    text: {
      primary: '#16113B',
      fadeTextPrimary: deepBlue[60],
      placeholder: deepBlue[50],
      secondary: white,
      black,
    },
    background: { main: grey[100] },
    cardBackground: white,
    borderColor,
    tableBorderColor,
    examinationTooltip: '#302B5A',
    drawer: {
      backgroundColor: 'rgba(64, 183, 190, 0.38)',
      activeItemBorder: `2px solid ${green}`,
      divider: '#302B5A',
      hover: menuHover,
      primaryText: '#E4E4E7',
      secondaryText: fade(white, 0.8),
    },
    tooltip: {
      backgroundColor: '#797C8A',
      backgroundColor2: '#6E6A8E',
    },
    graphBar: {
      backgroundColor: '#8CD4D8',
    },
    toolbar: {
      backgroundColor: green,
      color: white,
    },
    bookBanner: {
      background: 'rgba(63, 133, 244, 0.15)',
    },
    chip: {
      color: {
        light: white,
        dark: '#114B8C',
      },
    },
    modal: {
      color: white,
      background: 'rgba(0, 0, 0, 0.5)',
    },
    tableFilters: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: {
      greyColor: grey[500],
      icon: deepBlue[50],
      textColor: deepBlue[50],
    },
    error: {
      main: '#B10058',
      secondary: '#F26C5D',
      tertiary: '#D32F2F',
      background: 'rgba(242, 108, 93, 0.1)',
      semiLight: 'rgba(234, 175, 161, 0.3)',
      light: 'rgba(211, 47, 47, .03)',
      manualColor: '#B10558',
      manualBackground: 'rgba(177, 5, 88, 0.1)',
    },
    progressBar: {
      light: 'rgba(63, 133, 244, .2)',
      minLight: 'rgba(242, 108, 93, .2)',
    },
    success: { main: '#15C39A' },
    warning: { main: '#FF7A00' },
    graph: {
      middleReferenceLine: '#3F85F4',
    },
    infoBackground: 'rgba(242, 108, 93, 0.1)',
    endedActionButton: '#FF8A1F',
    linkActionButton: '#288DD0',
    annotationColor: '#7D7A8F',
    mediumOfferHeaderBackground: '#FFAD1A',
    examinationInProgress: '#FFAD1A',
    plainBarColor: '#FFAD1A',
    orange: '#FF7A00',
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Red Hat Text',
    ],
  },
  sizes: {
    addEdit: {
      wrapperWidth: '100%',
    },
    cardContent: {
      width: 936,
    },
    bannerContainer: {
      content: {
        width: 625,
      },
      image: {
        width: 311,
      },
    },
    drawer: {
      width: 256,
      collapsedWidth: 96,
    },
    resultGraph: {
      dot: 14,
    },
    scrollbar: {
      width: 17,
    },
    versionPopup: {
      height: 140,
      minWidthForFullWidthPages: 988,
      minWidthForPagesWithMenu: 992,
    },
  },
  truncateMaxLength: 200,
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 16px',
        lineHeight: '20px',
        height: 36,
      },
      label: {
        lineHeight: 1.43,
      },
      containedPrimary: {
        color: white,
      },
      containedSecondary: {
        color: white,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.10), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: '#0BE0B9',
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: '#FFA400',
        },
      },
      text: {
        padding: '8px 16px',
        '&:hover': {
          color: white,
        },
        transition: noBackgroundButtonTransition,
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        opacity: 0.15,
      },
    },
    MuiTablePagination: {
      selectRoot: {
        marginRight: 8,
        marginLeft: 4,
      },
      select: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      selectIcon: {
        top: 'auto',
      },
      toolbar: {
        minHeight: 32,
        paddingTop: 26,
      },
    },
    MuiInputLabel: {
      formControl: {
        color: deepBlue[50],
      },
      shrink: {
        color: deepBlue[50],
      },
      outlined: {
        color: deepBlue[50],
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: 'top',
      },
      head: {
        color: grey[400],
      },
      stickyHeader: {
        top: -36,
        backgroundColor: '#fff',
        padding: '0 8px',
      },
    },
    MuiTab: {
      root: {
        padding: 12,
        lineHeight: '16px',
      },
      wrapper: {
        fontSize: 14,
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: 'flex-end',
        '&:hover': {
          color: grey[800],
        },
        '&:focus': {
          color: 'inherit',
        },
        '&.MuiTableSortLabel-active': {
          color: green,
          '& > svg': {
            color: `${green} !important`,
          },
        },
      },
      icon: {
        color: `${grey[800]} !important`,
        width: 18,
        height: 18,
        marginBottom: -2,
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: grey[400],
      },
    },
    MuiList: {
      root: {
        backgroundColor: white,
      },
    },
    MuiCardContent: {
      root: {
        padding: 32,
        borderRadius: 8,
        '&:last-child': {
          paddingBottom: 32,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 8,
        border: `1px solid ${borderColor}`,
        boxShadow: '0px 5px 5px rgba(22, 17, 59, 0.1), 0px 8px 10px rgba(22, 17, 59, 0.14), 0px 3px 14px rgba(22, 17, 59, 0.12)',
      },
    },
    MuiMenuItem: {
      root: {
        borderBottom: `1px solid ${borderColor}`,
      },
    },
    MuiPaper: {
      elevation1: {
        borderRadius: '8px',
        border: `1px solid ${borderColor}`,
        boxShadow: '0px 2px 5px 1px rgba(22, 17, 59, 0.05), 0px 2px 15px 2px rgba(22, 17, 59, 0.03)',
      },
    },
    MuiTypography: {
      subtitle2: {
        color: white,
      },
    },
    MuiToolbar: {
      root: {
        background: white,
      },
    },
    MuiDrawer: {
      paper: {
        background: grey[900],
      },
    },
    MuiFab: {
      primary: {
        color: white,
      },
    },
    MuiDialogContentText: {
      root: {
        color: grey[800],
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: menuHover,
        borderRadius: 6,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 8,
      },
      adornedEnd: {
        color: grey[400],
      },
      multiline: {
        backgroundColor: white,
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom: `1px solid ${grey[300]} !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[500],
      },
    },
    MuiChip: {
      colorPrimary: {
        color: white,
      },
      label: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        margin: '12px 0 !important',
      },
    },
  },
  tableCell: {
    '&:not(th)': {
      lineHeight: '24px',
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  focus: {
    '& .MuiTouchRipple-child': {
      borderRadius: 4,
    },
  },
});

theme.actionsCell = {
  paddingTop: theme.spacing(1.375),
  paddingBottom: theme.spacing(1),
};

theme.buttonCell = {
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
};

theme.numberCell = {
  width: '1%',
  paddingRight: theme.spacing(1),
  wordBreak: 'keep-all',
};

theme.tooltip = {
  backgroundColor: '#6E6A8E',
  fontSize: 12,
  borderRadius: 2,
  fontWeight: 400,
  padding: theme.spacing(0.5, 1.5),
};

export default theme;
