import { defineMessages } from 'react-intl';

export default defineMessages({
  diagnosticianNote: 'Notatka diagnosty',
  addNote: 'Dodaj',
  title: 'Szczegóły badania grupowego',
  surveyName: 'Nazwa badania',
  participantAmount: 'Liczba uczestników',
  class: 'Klasa',
  section: 'Oddział',
  avarageSurveyTime: 'Średni czas wykonania badania',
  selectedTests: 'Wybrane testy',
  classError: 'Wartość musi być liczbą całkowitą nieujemną',
});
