import { makeStyles } from '@material-ui/styles';

import { getMenuWidth } from 'components/PageHeader/PageHeader.styles';

export default makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1201,
    backgroundColor: theme.palette.modal.background,
  },
  shiftedContent: ({ isCollapsedMenu }) => ({
    [`@media (min-width: ${theme.spacing(8) + theme.sizes.cardContent.width + getMenuWidth(isCollapsedMenu, theme)}px)`]: {
      marginLeft: (getMenuWidth(isCollapsedMenu, theme) - theme.sizes.scrollbar.width) / 2,
    },
  }),
  content: {
    padding: 0,
    maxWidth: '100vw',
    margin: 0,
    marginLeft: theme.spacing(-1),
    [`@media (max-width: ${theme.sizes.cardContent.width + theme.spacing(8)}px)`]: {
      marginLeft: 0,
    },
    left: '50%',
    bottom: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: theme.palette.modal.color,
    WebkitOverflowScrolling: 'touch',
    borderRadius: 8,
    outline: 'none',
  },
  modalBoxInnerWrapper: {
    maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    overflow: 'auto',
  },
  modalBoxInnerWrapperAvoidScroll: {
    maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    overflow: 'hidden',
  },
  box: {
    maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: theme.sizes.cardContent.width,
  },
  dynamicBox: {
    overflow: 'hidden',
  },
  fullWidth: {
    width: theme.sizes.cardContent.width,
  },
  body: {
    overflowY: 'hidden',
    '& main': {
      overflowY: 'hidden',
    },
  },
  modalBoxWrapper: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    background: '#fff',
    color: '#716E87',
    top: '-48px',
    '&:hover': {
      background: '#0AD5B0',
    },
  },
}));
