import { push } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';

import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import formatCodeWithName from 'utils/formatCodeWithName';

import {
  hideLoader,
  showLoader,
  setDrawerVisibility,
  fetchUsersClinics, setUserClinics,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  batteries: [],
  patientID: null,
};

const getToolData = (tool) => ({
  id: tool.id,
  name: formatCodeWithName(tool),
  description: tool.description,
  batteryId: tool.battery_id,
  hasEnoughTokens: tool.has_enough_tokens,
  order: tool.order,
});

const mapTools = (toolsData) => (
  _sortBy(_map(toolsData, (tool) => getToolData(tool)), 'order')
);

const mapBatteries = (batteries) => {
  const items = [];
  const singleTools = [];
  _forEach(batteries, (battery) => {
    const tools = battery.survey_tools;

    if (!battery.has_more_than_one_active_survey_tool) {
      singleTools.push(getToolData(tools[0]));
    } else if (tools.length > 0) {
      items.push({
        batteryName: formatCodeWithName(battery),
        description: battery.description,
        id: battery.id,
        utilities: mapTools(tools),
      });
    }
  });

  const singleTests = {
    isSingleTests: true,
    id: 'single',
    utilities: singleTools,
  };

  if (!_isEmpty(singleTools)) {
    items.push(singleTests);
  }

  return items;
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_START/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_START/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        batteries: mapBatteries(action.batteries.data.items),
        patientID: action.patientId,
        userWithEmail: action.userWithEmail,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (batteries, patientId, userWithEmail) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  batteries,
  patientId,
  userWithEmail,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadBatteries = (clinicId, patientId) => (dispatch) => {
  const data = {
    findByBatteryStatusInClinic: 'ACTIVE',
    withSurveyToolStatuses: ['ACTIVE'],
    clinicId,
    findByMatchingPatientId: patientId,
  };

  const url = createURLWithQuery('batteries', data);

  return ApiManager.request('get', dispatch, url);
};

export const onSelectUtility = (toolInfo) => async (dispatch, getStore) => {
  const { patientID, userWithEmail } = getStore().ResearchStart;
  dispatch(showLoader());

  try {
    const currentUser = await dispatch(fetchUsersClinics());
    dispatch(setUserClinics(currentUser.data.clinics));
  } catch (error) {
    errorCatch(error, dispatch);
  } finally {
    dispatch(hideLoader());
    dispatch(push('/researchSelect', { toolInfo, patientID, userWithEmail }));
  }
};

export const loadPageData = (
  userData,
  message,
  routeState,
) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  const clinicId = _get(userData, 'clinics[0].id', null);
  const patientId = _get(routeState, 'patientID', null);
  const userWithEmail = _get(routeState, 'userWithEmail', null);
  try {
    const batteries = await dispatch(loadBatteries(clinicId, patientId));
    dispatch(setDrawerVisibility({
      visibility: !_isEmpty(batteries.data.items),
      content: message,
    }));
    dispatch(loadPageSuccess(batteries, patientId, userWithEmail));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
